import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import {
  iconsSet as icons
} from './assets/icons/icons.js'
import store from './store'

import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

import Trend from "vuetrend"
Vue.use(Trend)

import Account from "./plugins/Account.js";
Vue.use(Account);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.use(require('vue-moment'));

import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})

// This is where we can intercept navigation changes
var postLoginPath = null;
router.beforeEach((to, from, next) => {
  if (RobocallsAI.shared().isLoggedIn()) {
    // User is logged in
    if (to.path == "/login") {
      // Don't allow user to go to the login page since they're already logged in
      next("/");
    } else if (to.path == "/logged-in") {
      if (postLoginPath) {
        next(postLoginPath);
      } else {
        next("/")
      }
    } else {
      next();
    }
  } else {
    // User is logged out
    if (to.path != "/login") {
      // Force the user to go to the login page
      postLoginPath = to.path;
      next("/login");
    } else {
      next();
    }
  }
})

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})

// Initialize the accounts manager which translates account UUIDs to account names
AccountsManager.shared().initialize()

// Initialize the storage manager which manages storing data to disk
StorageManager.shared().initialize()

// Initialize the SDK for interacting with the platform
RobocallsAI.shared().initialize();
RobocallsAI.shared().onSessionTerminated(() => {
  // Whenever a users session gets terminated we'll be notified here

  // Remove everything we've stored to disk that was tied to the session
  StorageManager.shared().removeSessionItems();

  // Display the login screen
  router.replace("/login");
})
