// This is where you add the icons you want to use
// Visit the following URL to find the icons you want, then add them below
//
// https://icons.coreui.io/icons/
// https://coreui.io/vue/demo/4.0/free/#/icons/coreui-icons

import {
  cilBalanceScale,
  cilBadge,
  cilBank,
  cilBrowser,
  cilChartLine,
  cilCheckCircle,
  cilCode,
  cilContact,
  cilDescription,
  cilExternalLink,
  cilFingerprint,
  cilGlobeAlt,
  cilHeart,
  cilHttps,
  cilLan,
  cilMediaPlay,
  cilMediaPause,
  cilPencil,
  cilRss,
  cilScreenDesktop,
  cilSearch,
  cilSpreadsheet,
  cilUserUnfollow,
  cilVolumeHigh,
  cilWarning,
  cibDocker,
  cibGithub,
  cilShareAlt,
  cilXCircle,
} from '@coreui/icons'

export const iconsSet = Object.assign({
  cilBalanceScale,
  cilBadge,
  cilBank,
  cilBrowser,
  cilChartLine,
  cilCheckCircle,
  cilCode,
  cilContact,
  cilDescription,
  cilExternalLink,
  cilFingerprint,
  cilGlobeAlt,
  cilHeart,
  cilHttps,
  cilLan,
  cilMediaPlay,
  cilMediaPause,
  cilPencil,
  cilRss,
  cilScreenDesktop,
  cilSearch,
  cilSpreadsheet,
  cilUserUnfollow,
  cilVolumeHigh,
  cilWarning,
  cibDocker,
  cibGithub,
  cilShareAlt,
  cilXCircle
})
