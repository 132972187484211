export default {

  install(Vue, options) {

    Vue.component('account', {
      props: {
        uuid: String
      },
      watch: {
        uuid: function() {
          this.refresh();
        }
      },
      data: function() {
        return {
          account: null
        }
      },
      computed: {
        accountName: function() {
          if (this.account) {
            if (!this.account.name) {
              return this.uuid;
            } else {
              return this.account.name;
            }
          } else {
            return null;
          }
        }
      },
      methods: {
        refresh() {
          var self = this;
          AccountsManager.shared().accountForUUID(this.uuid, function(account) {
            if (!account) {
              self.account = {};
            } else {
              self.account = account;
            }
          });
        }
      },
      mounted() {
        // Fetch the account object
        this.refresh();
      },
      beforeDestroy() {},
      template: `
        <span>
          <span v-if="accountName">
            {{ accountName }}
          </span>
          <span v-else>
            <div class="spinner-border spinner-border-sm text-primary" role="status">
              <span class="sr-only"></span>
            </div>
          </span>
        </span>`
    })
  }
}
