import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// The different views

const Auth = () => import('@/views/auth/_root')
const Auth_ClientAccounts = () => import('@/views/auth/client_accounts')
const Auth_ServiceAccounts = () => import('@/views/auth/service_accounts')
const Auth_UserAccounts = () => import('@/views/auth/user_accounts')

const Files = () => import('@/views/files/_root')
const Files_BlockLists = () => import('@/views/files/block-lists')
const Files_AllowLists = () => import('@/views/files/allow-lists')
const Files_Documents = () => import('@/views/files/documents')

const CallsRadar = () => import('@/views/calls-radar/_root')
const CallsRadar_AudioFingerprints = () => import('@/views/calls-radar/audio_fingerprints')

const Dashboard = () => import('@/views/Dashboard')

const DeveloperTools = () => import('@/views/developer-tools/_root')
const DeveloperTools_AdminPage = () => import('@/views/developer-tools/admin_page')
const DeveloperTools_APITester = () => import('@/views/developer-tools/api_tester')
const DeveloperTools_AudioFingerprintTester = () => import('@/views/developer-tools/audio_fingerprint_tester')
const DeveloperTools_GoogleCloudLinks = () => import('@/views/developer-tools/google_cloud_links')
const DeveloperTools_Ping = () => import('@/views/developer-tools/ping')
const DeveloperTools_ServiceTemplate = () => import('@/views/developer-tools/service_template')

const DomainReputation = () => import('@/views/domain-reputation/_root')
const DomainReputation_DomainLookup = () => import('@/views/domain-reputation/domain_lookup')
const DomainReputation_TopDomains = () => import('@/views/domain-reputation/top_domains')
const DomainReputation_Whois = () => import('@/views/domain-reputation/whois')

const Login = () => import('@/views/Login')

const NumberClassifier = () => import('@/views/number-classifier/_root')
const NumberClassifier_NumberOverride = () => import('@/views/number-classifier/number_override')
const NumberClassifier_PendingOverrides = () => import('@/views/number-classifier/pending_overrides')

const Reputation = () => import('@/views/reputation/_root')
const Reputation_ResetPhoneNumbers = () => import('@/views/reputation/reset-phone-numbers')

const Services = () => import('@/views/services/_root')
const Services_Service = () => import('@/views/services/service')

const SMSFilter = () => import('@/views/sms-filter/_root')
const SMSFilter_DailyHeatmap = () => import('@/views/sms-filter/daily_heatmap')
const SMSFilter_RecentMessages = () => import('@/views/sms-filter/recent_messages')
const SMSFilter_SenderOverride = () => import('@/views/sms-filter/sender_override')
const SMSFilter_Stats = () => import('@/views/sms-filter/stats')

const SMSFingerprint = () => import('@/views/sms-fingerprint/_root')
const SMSFingerprint_Explain = () => import('@/views/sms-fingerprint/explain')
const SMSFingerprint_Find = () => import('@/views/sms-fingerprint/find')

const Profiles = () => import('@/views/profiles/_root')
const Profiles_Numbers = () => import('@/views/profiles/numbers')
const Profiles_Sender = () => import('@/views/profiles/sender')
const Profiles_Fingerprint = () => import('@/views/profiles/fingerprint')
const Profiles_Domain = () => import('@/views/profiles/domain')

const SMSRadar = () => import('@/views/sms-radar/_root')
const SMSRadar_Domains = () => import('@/views/sms-radar/domains')
const SMSRadar_Domains_Live = () => import('@/views/sms-radar/domains_live')
const SMSRadar_Messages = () => import('@/views/sms-radar/messages')
const SMSRadar_Senders = () => import('@/views/sms-radar/senders')
const SMSRadar_Senders_Live = () => import('@/views/sms-radar/senders_live')

const SMSReputation = () => import('@/views/sms-reputation/_root')
const SMSReputation_RecentlyUpdated = () => import('@/views/sms-reputation/recently_updated')
const SMSReputation_ConflictingReputations = () => import('@/views/sms-reputation/conflicting_reputations')

const StatusPage = () => import('@/views/status-page/_root')

const KeywordSearch = () => import('@/views/keyword-search/_root')
const KeywordSearch_Search  = () => import('@/views/keyword-search/search')


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: configRoutes()
})

function configRoutes() {
  return [{
      path: '/',
      redirect: '/dashboard',
      name: '',
      component: TheContainer,
      children: [{
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard
      }, ]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/auth',
      name: '',
      component: TheContainer,
      children: [{
        path: 'auth',
        name: 'Auth',
        redirect: 'auth',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: Auth,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'client-accounts',
            redirect: "client-accounts",
            name: 'Client Accounts',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Auth_ClientAccounts,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'service-accounts',
            redirect: "service-accounts",
            name: 'Service Accounts',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Auth_ServiceAccounts,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'user-accounts',
            redirect: "user-accounts",
            name: 'User Accounts',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Auth_UserAccounts,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/developer-tools',
      name: '',
      component: TheContainer,
      children: [{
        path: 'developer-tools',
        name: 'Developer Tools',
        redirect: 'developer-tools',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: DeveloperTools,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'admin-page',
            redirect: "admin-page",
            name: 'Admin Page',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_AdminPage,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'api-tester',
            redirect: "api-tester",
            name: 'API Tester',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_APITester,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'audio-fingerprint-tester',
            redirect: "audio-fingerprint-tester",
            name: 'Audio Fingerprint Tester',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_AudioFingerprintTester,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'google-cloud-links',
            redirect: "google-cloud-links",
            name: 'Google Cloud Links',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_GoogleCloudLinks,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'ping',
            redirect: "ping",
            name: 'Ping',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_Ping,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'service-template',
            redirect: "service-template",
            name: 'Service Template',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DeveloperTools_ServiceTemplate,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/domain-reputation',
      name: '',
      component: TheContainer,
      children: [{
        path: 'domain-reputation',
        name: 'Domain Reputation',
        redirect: 'domain-reputation',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: DomainReputation,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'top-domains',
            redirect: "top-domains",
            name: 'Top Domains',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DomainReputation_TopDomains,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'domain-lookup',
            redirect: "domain-lookup",
            name: 'Domain Lookup',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DomainReputation_DomainLookup,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'domain-lookup/:domain',
            redirect: ":domain",
            name: 'Domain Lookup',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DomainReputation_DomainLookup
            }]
          },
          {
            path: 'whois',
            redirect: "whois",
            name: 'Whois',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DomainReputation_Whois
            }]
          },
          {
            path: 'whois/:domain',
            redirect: ":domain",
            name: 'Whois',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: DomainReputation_Whois
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/services',
      name: '',
      component: TheContainer,
      children: [{
        path: 'services',
        name: 'Services',
        redirect: 'services',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: Services,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: ':service',
            redirect: ':service',
            name: 'Service',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Services_Service,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Services_Service
                }
              }]
            }]
          },
        ]
      }]
    },


    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/sms-filter',
      name: '',
      component: TheContainer,
      children: [{
        path: 'sms-filter',
        name: 'SMS Filter',
        redirect: 'sms-filter',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: SMSFilter,
            children: [{
              path: '',
              name: '',
              components: {
                dailyHeatmap: SMSFilter_DailyHeatmap
              }
            }]
          },
          {
            path: 'recent-messages',
            redirect: "recent-messages",
            name: 'Recent Messages',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFilter_RecentMessages,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'sender-override',
            redirect: "sender-override",
            name: 'Sender Override',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFilter_SenderOverride,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'stats',
            redirect: "stats",
            name: 'Stats',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFilter_Stats,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/sms-fingerprint',
      name: '',
      component: TheContainer,
      children: [{
        path: 'sms-fingerprint',
        name: 'SMS Fingerprint',
        redirect: 'sms-fingerprint',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: SMSFingerprint,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'find',
            redirect: "find",
            name: 'Find',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFingerprint_Find,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'find/:message',
            redirect: ":message",
            name: 'Find',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFingerprint_Find
            }]
          },
          {
            path: 'explain',
            redirect: "explain",
            name: 'Explain',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSFingerprint_Explain,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/profiles',
      name: '',
      component: TheContainer,
      children: [{
        path: 'profiles',
        name: 'Profiles',
        redirect: 'profiles',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: Profiles,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'sender',
            redirect: 'sender',
            name: 'Sender',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Sender,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Sender
                }
              }]
            }]
          },
          {
            path: 'sender/:sender',
            redirect: ':sender',
            name: 'Sender',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Sender,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Sender
                }
              }]
            }]
          },
          {
            path: 'fingerprint',
            redirect: 'fingerprint',
            name: 'Fingerprint',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Fingerprint,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Fingerprint
                }
              }]
            }]
          },
          {
            path: 'fingerprint/:fingerprint',
            redirect: ':fingerprint',
            name: 'Fingerprint',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Fingerprint,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Fingerprint
                }
              }]
            }]
          },
          {
            path: 'numbers',
            redirect: 'numbers',
            name: 'Numbers',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Numbers,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Numbers
                }
              }]
            }]
          },
          {
            path: 'domain',
            redirect: 'domain',
            name: 'Domain',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Domain,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Domain
                }
              }]
            }]
          },
          {
            path: 'domain/:domain',
            redirect: ':domain',
            name: 'Domain',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Profiles_Domain,
              children: [{
                path: '',
                name: '',
                components: {
                  upload: Profiles_Domain
                }
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/files',
      name: '',
      component: TheContainer,
      children: [{
        path: 'files',
        name: 'Files',
        redirect: 'files',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: Files,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'block-lists',
            redirect: 'block-lists',
            name: 'Block Lists',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Files_BlockLists,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'allow-lists',
            redirect: 'allow-lists',
            name: 'Allow Lists',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Files_AllowLists,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'documents',
            redirect: 'documents',
            name: 'Documents',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Files_Documents,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/calls-radar',
      name: '',
      component: TheContainer,
      children: [{
        path: 'calls-radar',
        name: 'Calls Radar',
        redirect: 'calls-radar',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: CallsRadar,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'audio-fingerprints',
            redirect: "audio-fingerprints",
            name: 'Audio Fingerprints',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: CallsRadar_AudioFingerprints,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },

    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/sms-radar',
      name: '',
      component: TheContainer,
      children: [{
        path: 'sms-radar',
        name: 'SMS Radar',
        redirect: 'sms-radar',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: SMSRadar,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'domains',
            redirect: "domains",
            name: 'Domains',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSRadar_Domains,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'live-domains',
            redirect: "live-domains",
            name: 'Live Domains',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSRadar_Domains_Live,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'live-senders',
            redirect: "live-senders",
            name: 'Live Senders',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSRadar_Senders_Live,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'messages',
            redirect: "messages",
            name: 'Messages',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSRadar_Messages,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'senders',
            redirect: "senders",
            name: 'Senders',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSRadar_Senders,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },

    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/sms-reputation',
      name: '',
      component: TheContainer,
      children: [{
        path: 'sms-reputation',
        name: 'SMS Reputation',
        redirect: 'sms-reputation',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: SMSReputation,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'recently-updated',
            redirect: "recently-updated",
            name: 'Recently Updated',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSReputation_RecentlyUpdated,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'conflicting-reputations',
            redirect: "conflicting-reputations",
            name: 'Conflicting Reputations',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: SMSReputation_ConflictingReputations,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },



    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/keyword-search',
      name: '',
      component: TheContainer,
      children: [{
        path: 'keyword-search',
        name: 'Keyword Search',
        redirect: 'keyword-search',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: KeywordSearch_Search,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'search',
            redirect: 'search',
            name: 'Search',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: KeywordSearch_Search,
              children: [{
                path: '',
                name: '',
                components: {}
              }]
            }]
          },
        ]
      }]
    },


    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/number-classifier',
      name: '',
      component: TheContainer,
      children: [{
        path: 'number-classifier',
        name: 'Number Classifier',
        redirect: 'number-classifier',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: NumberClassifier,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'number-override',
            redirect: "number-override",
            name: 'Number Override',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: NumberClassifier_NumberOverride,
              children: [{
                path: '',
                name: ''
              }]
            }]
          },
          {
            path: 'pending-overrides',
            redirect: "pending-overrides",
            name: 'Pending Overrides',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: NumberClassifier_PendingOverrides,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/reputation',
      name: '',
      component: TheContainer,
      children: [{
        path: 'reputation',
        name: 'Reputation',
        redirect: 'reputation',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
            path: '',
            name: '',
            component: Reputation,
            children: [{
              path: '',
              name: '',
              components: {}
            }]
          },
          {
            path: 'reset-phone-numbers',
            redirect: "reset-phone-numbers",
            name: 'Reset Phone Numbers',
            component: {
              render(c) {
                return c('router-view')
              }
            },
            children: [{
              path: '',
              name: '',
              component: Reputation_ResetPhoneNumbers,
              children: [{
                path: '',
                name: ''
              }]
            }]
          }
        ]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/',
      redirect: '/status-page',
      name: '',
      component: TheContainer,
      children: [{
        path: 'status-page',
        name: 'Status Page',
        redirect: 'status-page',
        component: {
          render(c) {
            return c('router-view')
          }
        },
        children: [{
          path: '',
          name: '',
          component: StatusPage,
          children: [{
            path: '',
            name: '',
            components: {}
          }]
        }]
      }]
    },
    //--------------------------------------------------------------------------
    {
      path: '/login',
      name: 'Login',
      component: Login
    }
  ]
}
